define("discourse/plugins/discourse-ai/discourse/templates/connectors/topic-more-content/related-topics", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div
    id="related-topics"
    class="more-topics__list {{if this.hidden 'hidden'}}"
    role="complementary"
    aria-labelledby="related-topics-title"
    {{did-insert this.registerList}}
    {{will-destroy this.removeList}}
  >
    <h3 id="related-topics-title" class="more-topics__list-title">
      {{i18n "discourse_ai.related_topics.title"}}
    </h3>
    <div class="topics">
      <BasicTopicList @topics={{this.relatedTopics}} />
    </div>
  </div>
  */
  {
    "id": "Qp8RyuC6",
    "block": "[[[11,0],[24,1,\"related-topics\"],[16,0,[29,[\"more-topics__list \",[52,[30,0,[\"hidden\"]],\"hidden\"]]]],[24,\"role\",\"complementary\"],[24,\"aria-labelledby\",\"related-topics-title\"],[4,[38,2],[[30,0,[\"registerList\"]]],null],[4,[38,3],[[30,0,[\"removeList\"]]],null],[12],[1,\"\\n  \"],[10,\"h3\"],[14,1,\"related-topics-title\"],[14,0,\"more-topics__list-title\"],[12],[1,\"\\n    \"],[1,[28,[35,5],[\"discourse_ai.related_topics.title\"],null]],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"topics\"],[12],[1,\"\\n    \"],[8,[39,6],null,[[\"@topics\"],[[30,0,[\"relatedTopics\"]]]],null],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[\"div\",\"if\",\"did-insert\",\"will-destroy\",\"h3\",\"i18n\",\"basic-topic-list\"]]",
    "moduleName": "discourse/plugins/discourse-ai/discourse/templates/connectors/topic-more-content/related-topics.hbs",
    "isStrictMode": false
  });
});