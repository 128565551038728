define("discourse/plugins/discourse-ai/discourse/templates/admin-dashboard-sentiment", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="sentiment section">
    <div class="period-section">
      <div class="section-title">
        <h2>
          {{i18n "discourse_ai.sentiments.dashboard.title"}}
        </h2>
  
        <DashboardPeriodSelector
          @period={{this.period}}
          @setPeriod={{this.setPeriod}}
          @startDate={{this.startDate}}
          @endDate={{this.endDate}}
          @setCustomDateRange={{this.setCustomDateRange}}
        />
      </div>
    </div>
  
    <div class="section-body">
      <div class="charts">
        <AdminReport
          @dataSourceName="overall_sentiment"
          @filters={{this.filters}}
          @showHeader={{true}}
        />
  
        <AdminReport
          @dataSourceName="post_emotion"
          @filters={{this.filters}}
          @showHeader={{true}}
        />
      </div>
    </div>
  </div>
  */
  {
    "id": "f0GfBo39",
    "block": "[[[10,0],[14,0,\"sentiment section\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"period-section\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"section-title\"],[12],[1,\"\\n      \"],[10,\"h2\"],[12],[1,\"\\n        \"],[1,[28,[35,2],[\"discourse_ai.sentiments.dashboard.title\"],null]],[1,\"\\n      \"],[13],[1,\"\\n\\n      \"],[8,[39,3],null,[[\"@period\",\"@setPeriod\",\"@startDate\",\"@endDate\",\"@setCustomDateRange\"],[[30,0,[\"period\"]],[30,0,[\"setPeriod\"]],[30,0,[\"startDate\"]],[30,0,[\"endDate\"]],[30,0,[\"setCustomDateRange\"]]]],null],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[10,0],[14,0,\"section-body\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"charts\"],[12],[1,\"\\n      \"],[8,[39,4],null,[[\"@dataSourceName\",\"@filters\",\"@showHeader\"],[\"overall_sentiment\",[30,0,[\"filters\"]],true]],null],[1,\"\\n\\n      \"],[8,[39,4],null,[[\"@dataSourceName\",\"@filters\",\"@showHeader\"],[\"post_emotion\",[30,0,[\"filters\"]],true]],null],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[\"div\",\"h2\",\"i18n\",\"dashboard-period-selector\",\"admin-report\"]]",
    "moduleName": "discourse/plugins/discourse-ai/discourse/templates/admin-dashboard-sentiment.hbs",
    "isStrictMode": false
  });
});